@import 'tailwindcss';

:root,
html.p-theme-light {
  --p-color-bg-inverse: #e73163;

  --test-bg-color: yellow;

  .Polaris-IndexTable {
    .Polaris-IndexTable__TableCell {
      a > span {
        color: var(--p-color-text);
      }
    }
  }

  .Polaris-Frame__Content {
    padding-bottom: 64px;
  }
}



.Glide {
  .Glide-AuthContainer {
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.Polaris-TopBar-SearchField__Input,
.Polaris-TopBar-SearchField__Input:focus {
  color: var(--p-color-text);
}

.Polaris-TopBar-SearchField__Backdrop {
  background-color: #fff !important;
}

.Polaris-ResourceList__ResourceListWrapper
  .Polaris-ResourceList__HeaderWrapper {
  display: none;
}

.question {
  // width: 500px;
  height: 200px;
}

.question-container {
  width: 100%;
  height: 100%;
}

// Settings layout - Weird padding on settings content nested Frames
.Polaris-Frame__Main .Polaris-Frame__Main {
  padding-top: 0;
}

// .Polaris-Frame__Navigation--visible {
//   display: inline;
// }

.FileCard {
  &:hover .FileCard__Button {
    display: block;
  }
}

.black {
  color: "rgb(15, 23, 42)";
  stroke: "rgb(15, 23, 42)";
  fill: "rgb(15, 23, 42)";
}


.no-padding {
  padding: 0 !important;
}

.no-border {
  border: 0 !important;
}

.slick-dots li {
  display: flex !important;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.slick-dots li.slick-active {
  background-color: rgb(25, 118, 210);
  color: white;
}

.slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.slick-slide img {
  max-width: 100%;
  height: auto;
}


.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}